html,
body,
#root,
.App {
  width: 100%;
  max-width: 100%
}

.container {
  height: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

.content {
  padding: 16px
}

.trubbl-color {
  color: #DB0A5A
}

.channel-header {
  background-color: #f9f9f9;
  filter: drop-shadow(3px 0px 5px #000000);
  padding: 12px 16px;
}

.channel-banner-header p,
.channel-header p {
  font-size: 24px;
  padding: 0;
  margin: 0;
}

.channel-banner-header p {
  font-size: 22px;
}

.submit-button {
  border: none
}

.btn-primary {
  background-color: #DB0A5A;
}

.channel-title {
  border-radius: 12px;
  background: var(--gray-white, #FFF);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
  padding: 16px;
}

.report-form label {
  font-size: 20px
}
